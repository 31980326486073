import { useMemo } from "react";
import { Grid } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { BidProductInfo } from "@sellernote/_shared/src/types/forwarding/bid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  findPortItem,
  getCountryOfPort,
} from "@sellernote/_shared/src/utils/forwarding/adminBidCreate";

import CheckPoint from "../components/CheckPoint";
import ExchangeRateInfo from "../components/ExchangeRateInfo";
import ExportCheckPointAndAddition from "../components/ExportCheckPointAndAddition";
import PortStep from "../components/PortStep";
import SummaryHeader from "../components/SummaryHeader";

import ItemList from "./ItemList";

export default function ItemInfoForBidCreate({
  exchangeRateList,
  bidCreateFormData,
  portList,
}: {
  exchangeRateList: ExchangeRate[];
  bidCreateFormData: BidCreateFormData;
  portList: Port[];
}) {
  const portStepData = useMemo(() => {
    return {
      serviceType: bidCreateFormData.serviceType,
      startAddress: bidCreateFormData.startAddress,
      endCountry:
        bidCreateFormData.endType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.endPort)
          : getCountryOfPort(portList, bidCreateFormData.endViaPort),
      startCountry:
        bidCreateFormData.startType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.startPort)
          : getCountryOfPort(portList, bidCreateFormData.startViaPort),
      startPort: bidCreateFormData.startPort
        ? findPortItem(portList, bidCreateFormData.startPort)
        : undefined,
      endPort: bidCreateFormData.endPort
        ? findPortItem(portList, bidCreateFormData.endPort)
        : undefined,
      startViaPort: bidCreateFormData.startViaPort
        ? findPortItem(portList, bidCreateFormData.startViaPort)
        : undefined,
      endViaPort: bidCreateFormData.endViaPort
        ? findPortItem(portList, bidCreateFormData.endViaPort)
        : undefined,
      endAddress: bidCreateFormData.endAddress,
      endAddressDetail: bidCreateFormData.endAddressDetail,
      incoterms: bidCreateFormData.incoterms,
      startAddressDetail: bidCreateFormData.startAddressDetail,
      // TODO: 창고 의뢰생성 추가 후 수정 예정
      zone: null,
      isImport: bidCreateFormData.bidType === "import",
    };
  }, [bidCreateFormData, portList]);

  return (
    <Grid container spacing={3}>
      <Grid item container xs={12}>
        <SummaryHeader
          freightType={bidCreateFormData.freightType}
          // 실제 의뢰가 생성된 것이 아니라서 0으로 표시
          id={0}
          supply={bidCreateFormData.supply}
        />
      </Grid>

      <Grid item container xs={12}>
        {portStepData && <PortStep portStepData={portStepData}></PortStep>}
      </Grid>

      <Grid item container xs={12}>
        <ItemList
          freightType={bidCreateFormData.freightType}
          containerAccessable={
            bidCreateFormData.containerAccessable
              ? bidCreateFormData.containerAccessable === "TRUE"
              : false
          }
          containersInfo={bidCreateFormData.containersInfo || []}
          productsInfo={bidCreateFormData.productsInfo as BidProductInfo[]}
        />
      </Grid>

      <Grid item container xs={12}>
        {bidCreateFormData.bidType === "import" && (
          <CheckPoint
            containDomesticFee={bidCreateFormData.containDomesticFee === "TRUE"}
            containOceanSurcharge={
              bidCreateFormData.containOceanSurcharge === "TRUE"
            }
            containLss={bidCreateFormData.containLss === "TRUE"}
            containerAccessable={
              bidCreateFormData.containerAccessable === "TRUE"
            }
            hopeCargoInsurance={bidCreateFormData.hopeCargoInsurance}
            freightType={bidCreateFormData.freightType}
            locale={"KR"}
            needIORAgency={false}
            needFTACertificateAgency={
              bidCreateFormData.needFTACertificateAgency ?? false
            }
          />
        )}

        {/* 수출 의뢰생성 일때 체크 및 부가서비스 항목 */}
        {bidCreateFormData.bidType === "export" && (
          <ExportCheckPointAndAddition
            containerDevanning={bidCreateFormData.containerDevanning === "TRUE"}
            containerStuffing={bidCreateFormData.containerStuffing === "TRUE"}
            cfsReceiving={bidCreateFormData.cfsReceiving}
            originInland={bidCreateFormData.inlandTransportType ?? null}
            useVGM={bidCreateFormData.useVGM}
            containCustoms={bidCreateFormData.importCustoms}
            containExportCustoms={bidCreateFormData.exportCustoms}
            hopeCargoInsurance={bidCreateFormData.hopeCargoInsurance}
            freightType={bidCreateFormData.freightType}
          />
        )}
      </Grid>
      <Grid item container xs={12}>
        <ExchangeRateInfo exchangeRateList={exchangeRateList} />
      </Grid>
    </Grid>
  );
}
