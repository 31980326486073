import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  AlignLeftOutlined,
  CompassOutlined,
  DesktopOutlined,
  FormOutlined,
  GiftOutlined,
  GlobalOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import GroupIcon from "@mui/icons-material/Group";
import RocketOutlinedIcon from "@mui/icons-material/RocketOutlined";
import ScheduleSharpIcon from "@mui/icons-material/ScheduleSharp";
import ScienceIcon from "@mui/icons-material/Science";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import Tooltip from "@mui/material/Tooltip";
import { detect } from "detect-browser";
import { useAtomValue, useSetAtom } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";

import { AdminMenuTree } from "../../types/adminMenu";

import LayoutForAdmin from "../../components/LayoutForAdmin";

import {
  FORWARDING_ADMIN_AUTH_ATOMS,
  FORWARDING_ADMIN_AUTH_SELECTORS,
} from "../../jotaiStates/auth";
import Styled from "./index.styles";

const MUI_ICON_STYLE = {
  color: "#eeeeee",
  fontSize: "17px",
  cursor: "pointer",
};

const SHIPDA_ADMIN_MENU_TREE: AdminMenuTree = [
  {
    label: "기본",
    mainMenuList: [
      {
        type: "link",
        icon: <AlignLeftOutlined key="icon" />,
        label: "대시보드",
        link: "/dashboard",
      },
      {
        type: "parent",
        icon: <GiftOutlined key="icon" />,
        label: "프로모션 관리",
        subMenuList: [
          {
            type: "link",
            label: "프로모션 관리",
            link: "/promotion",
          },

          {
            type: "link",
            label: "이메일 브로셔",
            link: "/promotion/email-brochures",
          },
        ],
      },
      {
        type: "parent",
        icon: <GiftOutlined key="icon" />,
        label: "영업 관리",
        subMenuList: [
          {
            type: "link",
            label: "영업 현황",
            link: "/business-management/bid",
          },
          {
            type: "link",
            label: "운영/정산 현황",
            link: "/business-management/operation",
          },
          {
            type: "link",
            label: "손익 현황",
            link: "/business-management/profit",
          },
          {
            type: "link",
            label: "가이드 신청 리스트",
            link: "/business-management/guide",
          },
        ],
      },
      {
        type: "link",
        icon: <SolutionOutlined key="icon" />,
        label: "발주 관리",
        link: "/order",
      },
      {
        type: "parent",
        icon: <SolutionOutlined key="icon" />,
        label: "운송 의뢰",
        subMenuList: [
          {
            type: "link",
            label: "선적계획 의뢰",
            link: "/bid",
          },
          {
            type: "link",
            label: "선적미계획 의뢰",
            link: "/order/sub",
          },
        ],
      },
      {
        type: "parent",
        icon: <GlobalOutlined key="icon" />,
        label: "운영 Trello",
        subMenuList: [
          {
            type: "link",
            label: "일반화물",
            link: "/trello/general",
          },
          {
            type: "link",
            label: "특송화물",
            link: "/trello/consolidation",
          },
          {
            type: "link",
            label: "수출화물",
            link: "/trello/export",
          },
        ],
      },
      {
        type: "parent",
        icon: <GlobalOutlined key="icon" />,
        label: "정산",
        subMenuList: [
          {
            type: "link",
            label: "정산",
            link: "/settlement",
          },
          {
            type: "link",
            label: "매출 관리",
            link: "/settlement/salesManagement",
          },
          {
            type: "link",
            label: "매입 관리",
            link: "/settlement/purchaseManagement",
          },
          {
            type: "link",
            label: "입금 관리",
            link: "/settlement/deposit-management",
          },
          {
            type: "link",
            label: "출금 관리",
            link: "/settlement/withdrawal-management",
          },
        ],
      },
      {
        type: "link",
        icon: <GiftOutlined key="icon" />,
        label: "쿠폰",
        link: "/coupon",
      },
      {
        type: "parent",
        icon: <ScheduleSharpIcon sx={MUI_ICON_STYLE} />,
        label: "운항 스케줄",
        subMenuList: [
          {
            type: "link",
            label: "FCL",
            link: "/vessel-schedule/fcl",
          },
          {
            type: "link",
            label: "LCL(항구)",
            link: "/vessel-schedule/lcl/port",
          },
          {
            type: "link",
            label: "LCL(창고)",
            link: "/vessel-schedule/lcl/warehouse",
          },
          {
            type: "link",
            label: "AIR",
            link: "/vessel-schedule/air",
          },
        ],
      },
      {
        type: "parent",
        icon: <ScienceIcon sx={MUI_ICON_STYLE} />,
        label: "실험실",
        subMenuList: [
          {
            type: "link",
            label: "입금관리 실험실",
            link: "/lab/deposit-management",
          },
        ],
      },
    ],
  },
  {
    label: "계정",
    mainMenuList: [
      {
        type: "link",
        icon: <GroupIcon sx={MUI_ICON_STYLE} />,
        label: "팀 관리",
        link: "/team/list",
      },

      {
        type: "link",
        icon: <GroupIcon sx={MUI_ICON_STYLE} />,
        label: "파트너 관리",
        link: "/partner-management",
      },

      {
        type: "link",
        icon: <SolutionOutlined key="icon" />,
        label: "거래처 관리",
        link: "/account-management",
      },

      {
        type: "link",
        icon: <UserOutlined key="icon" />,
        label: "사용자 관리",
        link: "/users/list",
      },

      {
        type: "link",
        icon: <DesktopOutlined key="icon" />,
        label: "관리자 설정",
        link: "/admin-user",
      },

      /**
       * 신규 파트너 관리 배포로, Legacy 파트너 관리 메뉴는 잠깐 숨김 (긴급상황 대비 삭제는 X)
       * 메뉴에서 접근은 숨기고, URL을 통한 라우팅은 가능하도록 함
       */
      // {
      //   type: "parent",
      //   icon: <SolutionOutlined key="icon" />,
      //   label: "Legacy 파트너 관리",
      //   subMenuList: [
      //     {
      //       type: "link",
      //       label: "해외",
      //       link: "/partner/foreign",
      //     },
      //     {
      //       type: "link",
      //       label: "국내",
      //       link: "/partner/domestic",
      //     },
      //     {
      //       type: "link",
      //       label: "운영파트너",
      //       link: "/partner/operator",
      //     },
      //     {
      //       type: "link",
      //       label: "관세사어드민",
      //       link: "/partner/customs",
      //     },
      //   ],
      // },
    ],
  },
  {
    label: "운임/요금",
    mainMenuList: [
      {
        type: "parent",
        icon: <FormOutlined key="icon" />,
        label: "운임 관리",
        subMenuList: [
          {
            type: "link",
            label: "FCL 운임",
            link: "/fare-management/fcl/list",
          },
          {
            type: "link",
            label: "LCL 운임",
            link: "/fare-management/lcl/list",
          },
          {
            type: "link",
            label: "AIR 운임",
            link: "/fare-management/air/list",
          },
        ],
      },
      {
        type: "parent",
        icon: <RocketOutlinedIcon sx={MUI_ICON_STYLE} />,
        label: "내륙요금 관리",
        subMenuList: [
          {
            type: "link",
            label: "FCL",
            link: "/inland-fare/fcl/list",
          },
          {
            type: "link",
            label: "LCL",
            link: "/inland-fare/lcl/list",
          },
          {
            type: "link",
            label: "AIR",
            link: "/inland-fare/air/list",
          },
        ],
      },
      {
        type: "parent",
        icon: <CompassOutlined key="icon" />,
        label: "Place",
        subMenuList: [
          {
            type: "link",
            label: "장소 관리",
            link: "/place/zones",
          },
          {
            type: "link",
            label: "향구/공항 관리",
            link: "/place/ports",
          },
          {
            type: "link",
            label: "국가 관리",
            link: "/place/countryList",
          },
        ],
      },
    ],
  },
];

const PARTNER_ADMIN_MENU_TREE: AdminMenuTree = [
  {
    label: "기본",
    mainMenuList: [
      {
        type: "parent",
        icon: <SolutionOutlined key="icon" />,
        label: "의뢰",
        subMenuList: [
          {
            type: "link",
            label: "쉽다 의뢰",
            link: "/bid",
          },
        ],
      },
      {
        type: "parent",
        icon: <GlobalOutlined key="icon" />,
        label: "운영 Trello",
        subMenuList: [
          {
            type: "link",
            label: "일반화물",
            link: "/trello/general",
          },
          {
            type: "link",
            label: "특송화물",
            link: "/trello/consolidation",
          },
        ],
      },
    ],
  },
];

const browser = detect();

function SupportedBrowserAlert() {
  return browser?.name !== "chrome" ? (
    <Styled.browserAlert
      message="본 서비스는 크롬브라우저에서만 정상 작동합니다"
      type="error"
    />
  ) : null;
}

function LayoutForShipdaAdmin({
  breadcrumbs,
  title,
  children,
}: {
  breadcrumbs?: string[];
  title?: string;
  children: React.ReactNode;
}) {
  const history = useHistory();

  const setLoggedIn = useSetAtom(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/login";
  }, [setLoggedIn]);

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  return (
    <LayoutForAdmin
      menuTree={SHIPDA_ADMIN_MENU_TREE}
      logo={
        <>
          <img src="/images/logo.png" alt="logo" style={{ width: "80px" }} />
          BackOffice
        </>
      }
      headerItems={[
        <div className="name" key={0}>
          {currentAdminAuthInfo?.accountId}
        </div>,
        <Tooltip title="비밀번호 변경" key={1}>
          <VpnKeyOutlinedIcon
            sx={{ color: "#9e9e9e", fontSize: "24px", cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/admin-user/change-password/${currentAdminAuthInfo?.id}`
              )
            }
          />
        </Tooltip>,

        <VideogameAssetOutlinedIcon
          sx={{ color: "#9e9e9e", fontSize: "24px", cursor: "pointer", ml: 1 }}
          key={2}
          onClick={() =>
            window.open("https://geunee92.github.io/my-suika-game/")
          }
        />,
      ]}
      onLogout={handleLogout}
      title={title}
      breadcrumbs={breadcrumbs}
    >
      <SupportedBrowserAlert />

      {children}
    </LayoutForAdmin>
  );
}

function LayoutForPartnerAdmin({
  title,
  breadcrumbs,
  children,
}: {
  title?: string;
  breadcrumbs?: string[];
  children: React.ReactNode;
}) {
  const history = useHistory();

  const setLoggedIn = useSetAtom(
    FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/login";
  }, [setLoggedIn]);

  const currentUser = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_PARTNER_ADMIN_AUTH_INFO
  );

  return (
    <LayoutForAdmin
      menuTree={PARTNER_ADMIN_MENU_TREE}
      logo={
        <>
          <img src="/images/logo.png" alt="logo" style={{ width: "80px" }} />
          파트너 Admin
        </>
      }
      headerItems={[
        <div className="name" key={0}>
          {`${currentUser?.name}(${currentUser?.company})`}
        </div>,

        <Tooltip title="비밀번호 변경" key={1}>
          <VpnKeyOutlinedIcon
            sx={{
              color: "#9e9e9e",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(`/change-password`);
            }}
          />
        </Tooltip>,
      ]}
      onLogout={handleLogout}
      title={title}
      breadcrumbs={breadcrumbs}
    >
      <SupportedBrowserAlert />

      {children}
    </LayoutForAdmin>
  );
}

const layoutByAppName = {
  "shipda-admin": LayoutForShipdaAdmin,
  "partner-admin": LayoutForPartnerAdmin,
};

const Layout = layoutByAppName[APP_NAME as "shipda-admin" | "partner-admin"];

export default Layout;
